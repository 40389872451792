import { Request } from '@fivb/sdk';
import * as Sentry from '@sentry/browser';
import ky from 'ky';
export { HTTPError } from 'ky';

const defaultHeaders = {
	'Accept': 'application/json',
	'Content-Type': 'application/xml',
};

const defaultSettings = {
	credentials: 'include' as RequestCredentials,
	timeout: 60000,
};

export interface WebServiceErrorResponse {
	errors: { code: string }[];
}

export interface QueryResult<T = Record<string, unknown>> {
	data: T;
	nbItems: number;
	version: number;
}

export const APIVersion = {
	VIS2009: 'VIS2009',
	VISv2: 'VISv2',
	VISSharp: 'VISSharp',
} as const;

interface ClientOptions {
	headers?: HeadersInit;
	version?: (typeof APIVersion)[keyof typeof APIVersion];
}

const kHttpEnvironmentKey = 'http:environment';
const proxyUrl = import.meta.env.VITE_APP_API_URL;
let useHttpEnvironment =
	localStorage.getItem(kHttpEnvironmentKey) || import.meta.env.VITE_APP_DEFAULT_ENV || 'production';

export function changeEnvironment(environment: 'production' | 'uat' | 'development') {
	localStorage.setItem(kHttpEnvironmentKey, environment);
	useHttpEnvironment = environment;
}

function computeProxyEndpoint(version: (typeof APIVersion)[keyof typeof APIVersion]) {
	switch (version) {
		case APIVersion.VIS2009:
			return '/';
		case APIVersion.VISv2:
			return '/v2';
		case APIVersion.VISSharp:
			return '/v3';
	}
}

function sendMany<T>(requests: Array<string | Request>, options: ClientOptions = {}) {
	return Promise.allSettled(requests.map((request) => send<T>(request, options)));
}

async function send<T>(request: string | Request, options: ClientOptions = {}) {
	const version = options.version ?? APIVersion.VIS2009;
	const url = `${proxyUrl}/proxy`;

	const headers = {
		...defaultHeaders,
		...(options.headers ?? {}),
		'X-FIVB-Env': useHttpEnvironment,
		'X-FIVB-Version': version,
	};

	Sentry.addBreadcrumb({
		message: 'Sending request',
		category: 'http',
		level: 'info',
		data: {
			url,
			request: request.toString(),
		},
	});

	const response = await ky.post(url, {
		...defaultSettings,
		headers,
		body: request.toString(),
	});

	if (response.headers.get('content-type')?.includes('application/json')) {
		return response.json<QueryResult<T>>();
	}

	// Needed since Ky fails to parse empty responses
	if (response.status === 204) {
		return '' as unknown as QueryResult<T>;
	}

	const text = await response.text();
	throw new Error(`Invalid Content-Type: ${response.headers.get('content-type')} - ${text}`);
}

function get<T>(url: string, options: ClientOptions = {}) {
	const headers = {
		...defaultHeaders,
		...(options.headers ?? {}),
		'X-FIVB-Env': useHttpEnvironment,
	};

	Sentry.addBreadcrumb({
		message: 'Sending request',
		category: 'http',
		level: 'info',
		data: {
			url,
		},
	});

	return ky
		.get(`${proxyUrl}${url}`, {
			...defaultSettings,
			headers,
		})
		.json<T>();
}

function post<T>(url: string, body?: Record<any, any> | string, options: ClientOptions = {}) {
	const headers = {
		...defaultHeaders,
		'Content-Type': 'application/json',
		...(options.headers ?? {}),
		'X-FIVB-Env': useHttpEnvironment,
	};

	body = typeof body === 'object' ? JSON.stringify(body) : body;

	return ky
		.post(`${proxyUrl}${url}`, {
			...defaultSettings,
			headers,
			body,
		})
		.json<T>();
}

function destroy(url: string, options: ClientOptions = {}) {
	const headers = {
		...defaultHeaders,
		...(options.headers ?? {}),
		'X-FIVB-Env': useHttpEnvironment,
	};

	Sentry.addBreadcrumb({
		message: 'Sending request',
		category: 'http',
		level: 'info',
		data: {
			url,
		},
	});

	return ky.delete(`${proxyUrl}${url}`, {
		...defaultSettings,
		headers,
	});
}

async function sendRaw<T>(options: ClientOptions & RequestInit): Promise<T | string> {
	const { headers: customHeaders, ...restOptions } = options;
	const version = options.version ?? APIVersion.VIS2009;

	const headers = {
		...defaultHeaders,
		...(customHeaders ?? {}),
		'X-FIVB-Env': useHttpEnvironment,
		'X-FIVB-Version': version,
	};

	const response = await ky(`${proxyUrl}/proxy`, {
		...defaultSettings,
		headers,
		...restOptions,
	});

	if (response.headers.get('content-type')?.includes('application/json')) {
		return response.clone().json();
	}

	return response.clone().text();
}

function compute(endpoint: string) {
	const url = new URL(`${proxyUrl}${endpoint}`);
	url.searchParams.append('env', useHttpEnvironment);
	return url.toString();
}

function openTarget(target: string) {
	const url = `${proxyUrl}${target}?env=${useHttpEnvironment}`;
	window.open(url, '_blank');
}

export const http = {
	send,
	sendMany,
	sendRaw,
	get,
	post,
	delete: destroy,
	compute,
	openTarget,
};

if (import.meta.vitest) {
	const { it, describe, expect, vi } = import.meta.vitest;

	describe('http', () => {
		it('should serialize a request object', async () => {
			// @ts-expect-error Mocking ky
			vi.spyOn(ky, 'post').mockReturnValue({
				json: vi.fn(),
			});

			const request = new Request('GetVolleyTransfer', ['No']);
			await http.send(request);

			const url = `${proxyUrl}${computeProxyEndpoint(APIVersion.VIS2009)}`;

			expect(ky.post).toHaveBeenCalledTimes(1);
			expect(ky.post).toHaveBeenCalledWith(url, {
				...defaultSettings,
				headers: {
					...defaultHeaders,
					'X-FIVB-Env': useHttpEnvironment,
				},
				body: request.toString(),
			});
		});

		it('should follow the API version', async () => {
			// @ts-expect-error Mocking ky
			vi.spyOn(ky, 'post').mockReturnValue({
				json: vi.fn(),
			});

			const request = new Request('GetVolleyTransfer', ['No']);
			await http.send(request, { version: APIVersion.VISv2 });

			const url = `${proxyUrl}${computeProxyEndpoint(APIVersion.VISv2)}`;

			expect(ky.post).toHaveBeenCalledTimes(1);
			expect(ky.post).toHaveBeenCalledWith(url, {
				...defaultSettings,
				headers: {
					...defaultHeaders,
					'X-FIVB-Env': useHttpEnvironment,
				},
				body: request.toString(),
			});
		});

		it('should send the environment in a header when changed', async () => {
			// @ts-expect-error Mocking ky
			vi.spyOn(ky, 'post').mockReturnValue({
				json: vi.fn(),
			});

			changeEnvironment('uat');
			const request = new Request('GetVolleyTransfer', ['No']);
			await http.send(request);

			const url = `${proxyUrl}${computeProxyEndpoint(APIVersion.VIS2009)}`;

			expect(ky.post).toHaveBeenCalledTimes(1);
			expect(ky.post).toHaveBeenCalledWith(url, {
				...defaultSettings,
				headers: {
					...defaultHeaders,
					'X-FIVB-Env': 'uat',
				},
				body: request.toString(),
			});
		});

		it('should forward the headers', async () => {
			// @ts-expect-error Mocking ky
			vi.spyOn(ky, 'post').mockReturnValue({
				json: vi.fn(),
			});

			const request = new Request('GetVolleyTransfer', ['No']);
			await http.send(request, { headers: { 'X-FIVB-Test': 'true' } });

			const url = `${proxyUrl}${computeProxyEndpoint(APIVersion.VIS2009)}`;

			expect(ky.post).toHaveBeenCalledTimes(1);
			expect(ky.post).toHaveBeenCalledWith(url, {
				...defaultSettings,
				headers: {
					...defaultHeaders,
					'X-FIVB-Env': useHttpEnvironment,
					'X-FIVB-Test': 'true',
				},
				body: request.toString(),
			});
		});

		it('should serialize the body of a post request', async () => {
			// @ts-expect-error Mocking ky
			vi.spyOn(ky, 'post').mockReturnValue({
				json: vi.fn(),
			});

			const body = { test: 'true' };
			await http.post('/test', body);

			expect(ky.post).toHaveBeenCalledTimes(1);
			expect(ky.post).toHaveBeenCalledWith(`${proxyUrl}/test`, {
				...defaultSettings,
				headers: {
					...defaultHeaders,
					'X-FIVB-Env': useHttpEnvironment,
				},
				body: JSON.stringify(body),
			});
		});

		it('should compute the url of a target', () => {
			const url = http.compute('/test');
			expect(url).toBe(`${proxyUrl}/test?env=${useHttpEnvironment}`);
		});

		it('should open a target in a new tab', () => {
			vi.stubGlobal('window', {
				open: vi.fn(),
			});
			vi.spyOn(window, 'open');

			const target = '/target';
			http.openTarget(target);

			expect(window.open).toHaveBeenCalledTimes(1);
			expect(window.open).toHaveBeenCalledWith(`${proxyUrl}${target}?env=${useHttpEnvironment}`, '_blank');
		});

		it('should open a target in a new tab with a specific environment', () => {
			vi.stubGlobal('window', {
				open: vi.fn(),
			});
			vi.spyOn(window, 'open');

			const target = '/target';
			changeEnvironment('production');
			http.openTarget(target);

			expect(window.open).toHaveBeenCalledTimes(1);
			expect(window.open).toHaveBeenCalledWith(`${proxyUrl}${target}?env=production`, '_blank');
		});
	});
}
