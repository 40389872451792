<script lang="ts" setup>
	import type { FunctionalComponent } from 'vue';
	import IconBeachvolley from '~icons/fivb/beachvolley';
	import IconCaret from '~icons/fivb/carret';
	import IconCommunication from '~icons/fivb/communication';
	import IconCondition from '~icons/fivb/conditions';
	import IconDocument from '~icons/fivb/documents';
	import IconFlag from '~icons/fivb/flag';
	import IconGlobe from '~icons/fivb/globe';
	import IconPayment from '~icons/fivb/payments';
	import IconPhysical from '~icons/fivb/physical';
	import IconPin from '~icons/fivb/pin';
	import IconShirt from '~icons/fivb/shirt';
	import IconTransfer from '~icons/fivb/transfer';
	import IconTrophy from '~icons/fivb/trophy';
	import IconVolleyball from '~icons/fivb/volleyball';

	defineSlots<{
		default(): any;
		tools(): any;
		afterTitle(): any;
		footer(): any;
	}>();

	const props = defineProps({
		title: {
			type: String,
			required: false,
		},

		icon: {
			type: String,
			required: false,
		},

		scrollable: {
			type: Boolean,
			required: false,
			default: false,
		},

		strictWidth: {
			type: Boolean,
			required: false,
			default: false,
		},

		loading: {
			type: Boolean,
			required: false,
			default: false,
		},

		noPadding: {
			type: Boolean,
			required: false,
			default: false,
		},

		foldable: {
			type: Boolean,
			required: false,
			default: false,
		},

		expandedByDefault: {
			type: Boolean,
			required: false,
			default: true,
		},
	});

	const folded = ref(!props.expandedByDefault);

	let iconComponent: FunctionalComponent;

	switch (props.icon) {
		case 'shirt': {
			iconComponent = IconShirt;
			break;
		}
		case 'documents': {
			iconComponent = IconDocument;
			break;
		}
		case 'flag': {
			iconComponent = IconFlag;
			break;
		}
		case 'pin': {
			iconComponent = IconPin;
			break;
		}
		case 'payments': {
			iconComponent = IconPayment;
			break;
		}
		case 'conditions': {
			iconComponent = IconCondition;
			break;
		}
		case 'transfer': {
			iconComponent = IconTransfer;
			break;
		}
		case 'physical': {
			iconComponent = IconPhysical;
			break;
		}
		case 'globe': {
			iconComponent = IconGlobe;
			break;
		}
		case 'communication': {
			iconComponent = IconCommunication;
			break;
		}
		case 'volleyball': {
			iconComponent = IconVolleyball;
			break;
		}
		case 'beachvolley': {
			iconComponent = IconBeachvolley;
			break;
		}
		case 'trophy': {
			iconComponent = IconTrophy;
			break;
		}
	}
</script>

<template>
	<section
		class="relative rounded-lg bg-white shadow"
		:class="{
			'overflow-y-hidden': strictWidth,
		}"
	>
		<header
			v-if="title"
			class="flex items-center justify-between border-b border-gray-200 px-4 py-5 sm:px-6"
			:class="{ 'cursor-pointer': foldable }"
			@click="foldable ? (folded = !folded) : null"
		>
			<div class="inline-flex items-center">
				<Component :is="iconComponent" v-if="icon" class="mr-4 h-8 w-8 fill-current text-blue-500" />

				<h3 class="text-2xl font-medium leading-6 text-gray-900" v-html="title"></h3>

				<div v-if="!loading && $slots.afterTitle" class="inline-flex items-center">
					<slot name="afterTitle" />
				</div>
			</div>

			<div v-if="!loading" class="flex items-center justify-center gap-4">
				<slot name="tools" />

				<template v-if="foldable">
					<div class="inline-block">
						<IconCaret class="ml-auto h-4 fill-current transition-transform" :class="{ 'rotate-90': !folded }" />
					</div>
				</template>
			</div>
		</header>

		<template v-if="!loading">
			<div
				class="grid grid-rows-[0fr] transition-all"
				:class="{
					'grid-rows-[0fr]': foldable,
					'grid-rows-[1fr]': !folded,
				}"
			>
				<div
					class="space-y-1"
					:class="{ 'overflow-y-hidden': folded || strictWidth, 'overflow-y-auto': scrollable && !folded }"
				>
					<slot name="raw" />

					<div
						:class="{
							'px-4 py-5 sm:p-6': !noPadding,
							'p-px': noPadding,
						}"
					>
						<slot />
					</div>
				</div>
			</div>
		</template>

		<template v-else-if="!folded">
			<div class="mb-4"></div>

			<div class="flex h-12 items-center justify-center">
				<Loader color="#4299e1" />
			</div>
		</template>

		<footer v-if="$slots.footer" class="rounded-b-lg bg-slate-50">
			<slot name="footer" />
		</footer>
	</section>
</template>
