import { createAutoAnimatePlugin } from '@formkit/addons';
import { createProPlugin, datepicker, toggle } from '@formkit/pro';
import { generateClasses } from '@formkit/themes';
import { plugin, defaultConfig } from '@formkit/vue';
import type { App } from 'vue';

const datepickerStyle = {
	pickerHeader: 'flex items-center justify-center col-start-2 col-end-2',
	pickerButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-blue-500',
	pickerInput:
		'flex items-center justify-center cursor-pointer rounded-md bg-gray-200 aria-selected:bg-blue-500 aria-selected:text-white focus:outline focus:outline-2 focus:outline-blue-500 focus:outline-offset-2 focus:bg-white focus:text-black data-[is-extra=true]:opacity-25 formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none',
};

const proPlugin = createProPlugin('fk-510e055329', { datepicker, toggle });

export function setupFormKit(app: App<Element>): void {
	app.use(
		plugin,
		defaultConfig({
			plugins: [createAutoAnimatePlugin(), proPlugin],
			config: {
				classes: generateClasses({
					'global': {
						label: 'block text-sm font-medium text-gray-700',
						outer: 'w-full',
						input: 'w-full appearance-none bg-transparent focus:outline-none focus:ring-0 focus:shadow-none',
						inner: 'w-full formkit-disabled:cursor-not-allowed mt-1 overflow-hidden',
						help: 'block mt-1 text-blue-500 text-xs',
						message: 'block mt-2 text-red-500 text-xs',
						prefixIcon:
							'w-10 flex self-stretch grow-0 shrink-0 rounded-tl rounded-bl border-r border-gray-400 bg-white bg-gradient-to-b from-transparent to-gray-200 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
						suffixIcon:
							'w-7 pr-3 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
					},
					'family:dropdown': {
						inner:
							'max-w-md relative flex ring-1 ring-gray-400 focus-within:ring-blue-500 focus-within:ring-2 rounded mb-1 formkit-disabled:focus-within:ring-gray-400 formkit-disabled:focus-within:ring-1 [&>span:first-child]:focus-within:text-blue-500',
						input: 'w-full px-3 py-2',
					},
					'family:text': {
						inner:
							'flex items-center border border-gray-300 focus-within:ring-blue-500 focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500 rounded-md',
						input: 'w-full px-3 py-2 border-none text-base sm:text-sm placeholder-gray-400',
					},
					'family:date': {
						inner:
							'flex items-center border border-gray-300 focus-within:ring-blue-500 focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500 rounded-md',
						input: 'w-full px-3 py-2 border-none text-base sm:text-sm text-gray-700 placeholder-gray-400',
					},
					'file': {
						inner:
							'mt-1 py-2 px-3 relative flex items-center justify-between w-full border border-gray-300 rounded-md sm:text-sm formkit-errors:border-red-500',
						input:
							'z-10 absolute inset-0 cursor-pointer text-transparent file:appearance-none file:text-0 file:border-none file:p-0 rounded-md focus:ring-blue-500 focus:border-blue-500 placeholder:text-gray-400 placeholder:text-slate-400',
						noFiles: 'block text-sm font-medium text-gray-700',
						fileList: 'w-full',
						fileItem: 'block text-sm font-medium text-gray-700 flex items-center',
						fileRemove: 'relative ml-auto text-blue-500 text-sm z-20',
					},
					'checkbox': {
						label: '$reset ml-3 flex-grow font-medium text-gray-700',
						outer: 'w-full',
						inner: '$reset',
						wrapper: 'flex items-center',
						input: '$reset form-checkbox border-gray-300 rounded text-blue-600 transition duration-150 ease-in-out',
					},
					'select': {
						input:
							'block w-full px-3 py-2 text-base text-gray-700 border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 sm:text-sm placeholder:text-gray-400 placeholder:text-slate-400',
					},
					'datepicker': {
						inner: 'relative',
						overlayPlaceholder: 'text-sm font-medium text-gray-700',
						panelWrapper:
							'absolute bg-white rounded-md shadow border border-gray-300 p-4 z-10 top-[calc(100%_+_0.5em)]',
						daysHeader: 'flex items-center justify-center',
						panelHeader: 'grid grid-cols-[2.5em_1fr_2.5em] justify-center items-center border-b-2 mb-4 pb-4',
						monthsHeader: datepickerStyle.pickerHeader,
						timeHeader: datepickerStyle.pickerHeader,
						yearsHeader: datepickerStyle.pickerHeader,
						prev: 'mr-auto px-3 py-1 hover:bg-gray-100 hover:rounded-lg col-start-1 col-end-1',
						prevLabel: 'hidden',
						prevIcon: 'flex w-3 select-none [&>svg]:w-full',
						dayButton: datepickerStyle.pickerButton,
						monthButton: datepickerStyle.pickerButton,
						yearButton: datepickerStyle.pickerButton,
						years: 'flex flex-wrap max-w-[25em]',
						year: `${datepickerStyle.pickerInput} w-[calc(20%_-_1em)] m-2 p-2`,
						month: `${datepickerStyle.pickerInput} w-[calc(33%_-_1em)] m-2 p-2`,
						dayCell: `${datepickerStyle.pickerInput} w-[2.25em] h-[2.25em] m-1 p-2`,
						week: 'flex formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none',
						months: 'flex flex-wrap',
						weekDays: 'flex',
						weekDay: 'flex w-[2.25em] h-[1em] m-1 items-center justify-center rounded-md font-medium lowercase',
						next: 'ml-auto px-3 py-1 hover:bg-gray-100 hover:rounded col-start-3 col-end-3',
						nextLabel: 'hidden',
						nextIcon: 'flex w-3 select-none [&>svg]:w-full',
						openButton:
							'appearance-none border-0 bg-transparent flex p-0 self-stretch cursor-pointer  focus-visible:outline-none focus-visible:text-white focus-visible:bg-blue-500',
						calendarIcon:
							'flex w-8 grow-0 shrink-0 self-stretch select-none [&>svg]:w-full [&>svg]:m-auto [&>svg]:max-h-[1em] [&>svg]:max-w-[1em]',
					},
					'toggle': {
						altLabel: 'text-sm font-medium text-gray-700',
						inner: '$reset inline-block mr-2',
						input: 'peer absolute opacity-0 pointer-events-none',
						innerLabel: 'text-[10px] font-bold absolute left-full top-1/2 -translate-x-full -translate-y-1/2 px-1',
						thumb: 'relative left-0 aspect-square rounded-full transition-all w-5 bg-white border border-gray-300',
						track:
							'bg-gray-50 border border-gray-300 peer-checked:border-transparent p-0.5 min-w-[3em] relative rounded-full transition-all peer-checked:bg-blue-500 peer-checked:[&>div:last-child]:border-blue-500 peer-checked:[&>div:last-child]:left-full peer-checked:[&>div:last-child]:-translate-x-full peer-checked:[&>div:first-child:not(:last-child)]:left-0 peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0',
						valueLabel: 'text-sm font-medium text-gray-700',
						wrapper: 'flex flex-wrap items-center',
					},
					'textarea': {
						input:
							'py-2 px-3 relative flex items-center focus:ring-2 focus:ring-blue-500 justify-between w-full border border-gray-300 rounded-md sm:text-sm formkit-errors:border-red-500',
					},
				}),
			},
		})
	);
}
