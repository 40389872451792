import { queryKeyFactory } from '~/app/auth/queries/query-key-factory';
import { queryClient } from '~/infrastructure/query-client';
import type { User } from '~/app/auth/schemas/user';

export function useAuth() {
	const currentUser = queryClient.getQueryData<User>(queryKeyFactory.currentUser());

	if (!currentUser) {
		throw new Error('User is not authenticated');
	}

	return currentUser;
}
